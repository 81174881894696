import React from "react";
import { Grid } from "antd";
import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";

import { WrapperChartText, ContainerChartText } from "../Dashboard.style";
import { Trans, useTranslation } from "react-i18next";

const { useBreakpoint } = Grid;

const PieChartCustom = ({ data, colors, total, compared }: any) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });
  const screens = useBreakpoint();
  const size = screens.xl ? 155 : 185;
  const innerRadius = screens.xl ? 50 : 65;
  const outerRadius = screens.xl ? 75 : 90;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        transform: "rotateY(180deg)",
      }}
    >
      <div style={{ width: size, height: size, transform: "rotate(-90deg)" }}>
        <ResponsiveContainer>
          <PieChart>
            <Pie
              data={data}
              fill="#8884d8"
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              dataKey="value"
            >
              {data.map((entry: any, index: number) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[index % colors.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
      <WrapperChartText>
        <ContainerChartText>
          <div>
            {total}
            <span>{t("people")}</span>
          </div>
          <div>
            <Trans i18nKey="body-temperature.day-before">
              {{ compared: compared > 0 ? `+${compared}` : compared }}
            </Trans>
          </div>
        </ContainerChartText>
      </WrapperChartText>
    </div>
  );
};

export default PieChartCustom;
