import React, { useState, useEffect, useRef } from "react";
import { Col, Grid } from "antd";
import { useTranslation } from "react-i18next";

import { CloseIcon, DownloadIcon, NoDataIcon } from "icons";
import { generateImg, handleCheckValue } from "utils";

import {
  BoxItem4Chart,
  WrapperCloseIcon,
  BoxHeader,
  BoxTitle,
  LightDivider4Chart,
  BoxContent4Chart,
  WrapperLegend,
  RedRectangle,
  SilverRectangle,
} from "../Dashboard.style";
import PieChart from "./PieChart";
import { IChart } from "../BodyTemperatureResult";

const { useBreakpoint } = Grid;

const COLORS = ["#E0E0E0", "#FB4E4E"];

const BreakdownSymptoms: React.FC<IChart> = ({ data, handleCloseChart }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "body-temperature",
  });

  const imgRef = useRef<any>();
  const screens = useBreakpoint();
  const [dataChart, setDataChart] = useState({
    value: [],
    total: 0,
    compared: 0,
  });

  useEffect(() => {
    if (
      handleCheckValue(data?.nashi_percentage) &&
      handleCheckValue(data?.ari_percentage) &&
      handleCheckValue(data?.pie_symptoms_ari) &&
      handleCheckValue(data?.prev_dt_pie_symptoms_ari)
    ) {
      const value: any = [
        { value: data?.nashi_percentage },
        { value: data?.ari_percentage },
      ];
      const total = data?.pie_symptoms_ari;
      const compared = data?.pie_symptoms_ari - data?.prev_dt_pie_symptoms_ari;

      setDataChart({
        value,
        total,
        compared,
      });
    }
  }, [data]);

  return (
    <Col xs={24} md={12} xl={6} ref={imgRef}>
      <BoxItem4Chart>
        <WrapperCloseIcon>
          <CloseIcon
            fill="#2F8CAE"
            height="14px"
            width="14px"
            onClick={() => handleCloseChart("breakdownSymptoms")}
          />
        </WrapperCloseIcon>
        <BoxHeader>
          <BoxTitle>{t("breakdown-symptoms")}</BoxTitle>
          <DownloadIcon
            fill="#3B3B3B"
            style={{ marginTop: 4, cursor: "pointer" }}
            onClick={(event: any) => {
              if (!event.detail || event.detail == 1) {
                generateImg("breakdown-symptoms", imgRef.current);
              }
            }}
          />
        </BoxHeader>
        <LightDivider4Chart />
        <BoxContent4Chart>
          {dataChart.value.length ? (
            <React.Fragment>
              <PieChart
                data={dataChart.value}
                colors={COLORS}
                total={dataChart.total}
                compared={dataChart.compared}
              />
              {screens.xl && (
                <div
                  style={{
                    display: "flex",
                    marginTop: 40,
                    justifyContent: "center",
                  }}
                >
                  <WrapperLegend style={{ marginRight: 8 }}>
                    <RedRectangle />
                    <p>
                      {t("yes-chart")} <span>{data?.ari_percentage}%</span>
                    </p>
                  </WrapperLegend>
                  <WrapperLegend>
                    <SilverRectangle />
                    <p>
                      {t("none")}
                      <span> {data?.nashi_percentage}%</span>
                    </p>
                  </WrapperLegend>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: 17,
              }}
            >
              <NoDataIcon />
            </div>
          )}
        </BoxContent4Chart>
      </BoxItem4Chart>
    </Col>
  );
};

export default BreakdownSymptoms;
