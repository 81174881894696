import React, { useEffect, useMemo, useState } from "react";
import { Row, Col, Checkbox, Grid } from "antd";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { localStorageHelper } from "utils";
import { PlusIcon } from "icons";
import { Checkbox as CheckboxCustome, Collapse, Button } from "components";
import {
  optionFamilyIllness,
  optionPool,
  optionRegistrationTime,
  optionsMarathon,
  optionSymptoms,
} from "screens/messageDelivery/constants";

import { classListFilterDataResponse } from "redux/messagemanagement/classfilterlist/classFilterListStates";
import { activitiesListFilterDataResponse } from "redux/messagemanagement/activitiesfilterlist/activitiesFilterListStates";
import { selectIsLoading } from "redux/messagemanagement/getstudentlist/getStudentListStates";
import { IOption } from "components/select/Select";
import { AttendanceFilterData } from "models/messagemanagement/attendanceFilterList";
import {
  ActivitiesListFilterActionCreators,
  ClassListFilterActionCreators,
  GetAttendanceListFilterActionCreators,
  GetTemperatureListFilterActionCreators,
} from "redux/rootActions";

import {
  TopSearchCondition,
  ContentSearchCondition,
  SearchButton,
  SearchButtonContainer,
  SendAllStudentBtnContainer,
} from "./Questions.style";
import {
  SelectStyled,
  WrapperCollapse,
  SendAllStudentBtn,
  WrapperSearchCondition,
  CheckboxStyled,
} from "screens/messageDelivery/MessageDelivery.style";
import { FormStatus } from "./QuestionsTransmission";

const { useBreakpoint } = Grid;

interface ISearchCondition {
  showModal: (formStatus: FormStatus) => void;
  onSubmit: (data: any) => void;
  handleChangeSendToAllRegistrants: (value: boolean) => void;
  setDatas: any;
  datas: any;
  searchConditionDataApi: any;
  bodyTemperatures: any;
  attendanceOptions: any;
}

const SearchCondition: React.FC<ISearchCondition> = (props) => {
  const {
    showModal,
    onSubmit,
    handleChangeSendToAllRegistrants,
    setDatas,
    datas,
    searchConditionDataApi,
    bodyTemperatures,
    attendanceOptions,
  } = props;

  const { t }: any = useTranslation("translation", {
    keyPrefix: "message-delivery",
  });
  const { t: t1 }: any = useTranslation("translation", {
    keyPrefix: "user-list",
  });

  const dispatch = useDispatch();
  const screens = useBreakpoint();
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const classFilterlistdata = useSelector(classListFilterDataResponse);
  const activityFilterlistdata = useSelector(activitiesListFilterDataResponse);
  const isLoading = useSelector(selectIsLoading);

  const { handleSubmit } = useForm();

  const [poolValue, setPoolValue] = useState<string>();
  const [symptomValue, setSymptomValue] = useState<string>();
  const [attendanceValue, setAttendanceValue] = useState<string>();
  const [bodyTemperatureValue, setBodyTemperaturesValue] = useState<string>();
  const [timeValue, setTimeValue] = useState<string>();
  const [familyIllnessValue, setFamilyIllnessValue] = useState<string>();
  const [marathonValue, setMarathonValue] = useState<string>();
  const [gradeValue, setGradeValue] = useState<any>([]);
  const [valueCheckedGrade, setValueCheckedGrade] = useState<any>([]);
  const [activityValue, setActivityValue] = useState<any>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedGrade, setCheckedGrade] = useState<any>(false);
  const [valueCheckedActivity, setValueCheckedActivity] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState<{
    class: boolean;
    activities: boolean;
  }>({
    class: false,
    activities: false,
  });

  const handleOnChangeBodyTemperature = (value: IOption[]) => {
    setDatas((prevData: any) => ({ ...prevData, bodyTemperatures: value }));
    if (value) {
      const temp = value.map((value) => {
        return value.name;
      });
      if (bodyTemperatures.length == value.length) {
        setBodyTemperaturesValue(t("all"));
      } else {
        setBodyTemperaturesValue(temp.join("/"));
      }
    }
  };

  const handleOnChangeSymptom = (value: IOption[]) => {
    setDatas((prevData: any) => ({ ...prevData, symptoms: value }));
    if (value) {
      const temp = value.map((value) => {
        return t(value.name);
      });
      if (optionSymptoms.length == value.length) {
        setSymptomValue(t("all"));
      } else {
        setSymptomValue(temp.join("/"));
      }
    }
  };

  const handleOnChangeTime = (value: IOption[]) => {
    setDatas((prevData: any) => ({ ...prevData, times: value }));
    if (value) {
      const temp = value.map((value) => {
        return t(value.name);
      });
      if (optionRegistrationTime.length == value.length) {
        setTimeValue(t("all"));
      } else {
        setTimeValue(temp.join("/"));
      }
    }
  };

  const handleOnChangeAttendance = (value: IOption[]) => {
    setDatas((prevData: any) => ({ ...prevData, attendances: value }));
    if (value) {
      const temp = value.map((value) => {
        return value.name;
      });
      if (attendanceOptions.length == value.length) {
        setAttendanceValue(t("all"));
      } else {
        setAttendanceValue(temp.join("/"));
      }
    }
  };

  const handleOnChangePool = (value: IOption[]) => {
    setDatas((prevData: any) => ({ ...prevData, pools: value }));
    if (value) {
      const temp = value.map((value) => {
        return t(value.name);
      });
      if (optionPool.length == value.length) {
        setPoolValue(t("all"));
      } else {
        setPoolValue(temp.join("/"));
      }
    }
  };

  const handleOnChangeFamilyIllness = (value: IOption[]) => {
    setDatas((prevData: any) => ({ ...prevData, familyIllness: value }));
    if (value) {
      const temp = value.map((value) => {
        return t(value.name);
      });
      if (optionFamilyIllness.length == value.length) {
        setFamilyIllnessValue(t("all"));
      } else {
        setFamilyIllnessValue(temp.join("/"));
      }
    }
  };

  const handleOnChangeMarathon = (value: IOption[]) => {
    setDatas((prevData: any) => ({ ...prevData, marathons: value }));
    if (value) {
      const temp = value.map((value) => {
        return t(value.name);
      });
      if (optionsMarathon.length == value.length) {
        setMarathonValue(t("all"));
      } else {
        setMarathonValue(temp.join("/"));
      }
    }
  };

  const handleOnChangeGrade = (value: IOption[]) => {
    setDatas((prevData: any) => ({ ...prevData, grades: value }));
    setValueCheckedGrade(value);
    setCheckedGrade(value.length > 0 || false);

    if (checkAll) {
      setCheckAll(false);
    }
    if (value.length === classFilterlistdata?.result.length) {
      setCheckAll(true);
      setGradeValue(t("all"));
    } else {
      const temp: any = value.map((value) => {
        return value.name;
      });
      setGradeValue(temp);
    }
  };

  const handleOnChangeActivity = (value: IOption[]) => {
    setDatas((prevData: any) => ({ ...prevData, activities: value }));
    setValueCheckedActivity(value);
    if (value) {
      const temp = value.map((value) => {
        return value.name;
      });
      setActivityValue(temp);
    }
  };

  useEffect(() => {
    if (classFilterlistdata && classFilterlistdata?.result.length) {
      setDatas((prevData: any) => ({
        ...prevData,
        grades: classFilterlistdata?.result,
      }));
      setIsDisabled((prevState: any) => ({
        ...prevState,
        class: true,
      }));
    }
  }, [classFilterlistdata]);

  useEffect(() => {
    if (activityFilterlistdata && activityFilterlistdata?.result.length) {
      setIsDisabled((prevState: any) => ({
        ...prevState,
        activities: true,
      }));
    }
  }, [activityFilterlistdata]);

  const basicConditionItems = [
    {
      key: 1,
      label: "class",
      name: "class_name",
      options: classFilterlistdata?.result,
      marginForm: "0 0 16px",
      value: gradeValue,
      checkedValue: valueCheckedGrade,
      onChange: handleOnChangeGrade,
    },
    {
      key: 2,
      label: "activities",
      name: "activities",
      options: activityFilterlistdata?.result,
      marginForm: "0 0 32px",
      value: activityValue,
      checkedValue: valueCheckedActivity,
      onChange: handleOnChangeActivity,
    },
  ];

  const expandConditionItems = [
    {
      key: 0,
      label: "body-temperature",
      options: bodyTemperatures || {},
      selectValue: bodyTemperatureValue,
      onChange: handleOnChangeBodyTemperature,
      isShow: true,
      translate: false,
    },
    {
      key: 1,
      label: "symptoms",
      name: "symptoms",
      options: optionSymptoms,
      selectValue: symptomValue,
      onChange: handleOnChangeSymptom,
      isShow: true,
      translate: true,
    },
    {
      key: 2,
      label: "attendance-status",
      name: "attendance",
      options: attendanceOptions,
      selectValue: attendanceValue,
      onChange: handleOnChangeAttendance,
      isShow: searchConditionDataApi?.is_attendance_active || false,
      translate: false,
    },
    {
      key: 3,
      label: "registration-time",
      name: "time",
      options: optionRegistrationTime,
      selectValue: timeValue,
      onChange: handleOnChangeTime,
      isShow: true,
      translate: true,
    },
    {
      key: 4,
      label: "family-illness",
      name: "family_illness",
      options: optionFamilyIllness,
      selectValue: familyIllnessValue,
      onChange: handleOnChangeFamilyIllness,
      isShow: searchConditionDataApi?.is_family_illness_active || false,
      translate: true,
    },
    {
      key: 5,
      label: "pool",
      name: "pool_answer",
      options: optionPool,
      selectValue: poolValue,
      onChange: handleOnChangePool,
      isShow: searchConditionDataApi?.is_pool_active || false,
      translate: true,
    },
    {
      key: 6,
      label: "marathon",
      name: "marathon",
      options: optionsMarathon,
      selectValue: marathonValue,
      onChange: handleOnChangeMarathon,
      isShow: searchConditionDataApi?.is_marathon_active || false,
      translate: true,
    },
  ];

  const enabledSearchButton = useMemo(() => {
    let check = false;
    for (const property in datas) {
      if (datas[property].length) {
        if (property === "grades") {
          if (checkedGrade) {
            check = true;
          }
        } else {
          check = true;
        }
      }
    }
    return check;
  }, [datas]);

  const onCheckAllChange = (e: any) => {
    const { checked } = e.target;
    setCheckedGrade(checked || false);
    setGradeValue(checked ? t("all") : []);
    setValueCheckedGrade(checked ? classFilterlistdata?.result : []);
    setDatas((prevData: any) => ({
      ...prevData,
      grades: classFilterlistdata?.result,
    }));
    setCheckAll(checked);
  };

  useEffect(() => {
    dispatch(ClassListFilterActionCreators.classFilterListAction());
    dispatch(ActivitiesListFilterActionCreators.activitiesFilterListAction());
    dispatch(
      GetTemperatureListFilterActionCreators.temperatureFilterListAction({
        locale: currentLanguage,
      })
    );
    const params: AttendanceFilterData = {
      locale: currentLanguage,
    };
    dispatch(
      GetAttendanceListFilterActionCreators.attendanceFilterListAction(params)
    );
  }, []);

  return (
    <WrapperSearchCondition>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{ position: "relative" }}>
          <TopSearchCondition>
            <div>{t("search-condition")}</div>
            {screens.xl && (
              <Button
                type="submit"
                background="#2AC769"
                color="#ffffff"
                padding="12px 24px"
                name={t1("search")}
                border="none"
                bdr="6px"
                fontSize={16}
                lineHeight="16px"
                fontWeight={700}
                disabled={!enabledSearchButton || isLoading}
              />
            )}
          </TopSearchCondition>
          <ContentSearchCondition>
            <Row gutter={16}>
              {basicConditionItems.map((item, idx: number) => {
                return (
                  <Col xs={24} md={12} xl={24} key={item.key}>
                    <div
                      className="div-select"
                      style={{ position: "relative" }}
                    >
                      <SelectStyled
                        mode="multiple"
                        value={item.value}
                        label={t1(item.label)}
                        placeholder={t1("please-select")}
                        background="#F2F2F2"
                        fsLabel={16}
                        marginForm={item.marginForm}
                        showSearch={false}
                        showArrow
                        dropdownRender={() => (
                          <div
                            style={{
                              padding: "18px 14px 16px",
                              maxHeight: "300px",
                              overflowX: "auto",
                            }}
                          >
                            {!idx && (
                              <CheckboxStyled
                                onChange={onCheckAllChange}
                                checked={checkAll}
                                style={{ marginBottom: 18 }}
                              >
                                {t("all")}
                              </CheckboxStyled>
                            )}
                            <CheckboxCustome
                              value={item.checkedValue}
                              list={item.options}
                              onChange={item.onChange}
                            />
                          </div>
                        )}
                        suffixIcon={
                          <span
                            style={{
                              position: "absolute",
                              top: "0.5px",
                              right: "2px",
                              cursor: "pointer",
                            }}
                          >
                            <PlusIcon fill="#000" width="14px" height="14px" />
                          </span>
                        }
                      />
                    </div>
                  </Col>
                );
              })}
            </Row>
            <WrapperCollapse>
              <Collapse header={t("option-search")} expandIconPosition="right">
                <Row gutter={16}>
                  {expandConditionItems.map((item: any) => {
                    return item.isShow ? (
                      <Col xs={24} md={12} xl={24} key={item.key}>
                        <SelectStyled
                          defaultValue={t("all")}
                          value={item.selectValue}
                          label={t(item.label)}
                          marginForm="0 0 24px"
                          fs={14}
                          fsLabel={16}
                          placeholder={t("all")}
                          dropdownRender={() => (
                            <div style={{ padding: "20px 14px 16px" }}>
                              <Checkbox.Group
                                style={{ width: "100%" }}
                                onChange={item.onChange}
                              >
                                {item.options.map((a: any, index: number) => {
                                  return (
                                    <div
                                      style={{
                                        marginBottom:
                                          index < item.options.length - 1
                                            ? "12px"
                                            : "0px",
                                      }}
                                      key={a.id}
                                    >
                                      <CheckboxStyled value={a}>
                                        {item.translate ? t(a.name) : a.name}
                                      </CheckboxStyled>
                                    </div>
                                  );
                                })}
                              </Checkbox.Group>
                            </div>
                          )}
                        />
                      </Col>
                    ) : (
                      ""
                    );
                  })}
                </Row>
              </Collapse>
            </WrapperCollapse>
          </ContentSearchCondition>
        </div>
        {!screens.xl && (
          <SearchButtonContainer>
            <SearchButton
              type="submit"
              padding="11px 24px 13px"
              background="#2AC769"
              color="#fff"
              name={t1("search")}
              border="none"
              fontWeight={700}
              fontSize={16}
              lineHeight="16px"
              bdr="6px"
              disabled={!enabledSearchButton || isLoading}
            />
          </SearchButtonContainer>
        )}
      </form>
      <SendAllStudentBtnContainer>
        <SendAllStudentBtn
          name={"登録者全員にアンケートを送信"}
          type="submit"
          background="#FB4E4E"
          color="#fff"
          bdr="6px"
          border="none"
          fontWeight={700}
          fontSize={16}
          onClick={() => {
            handleChangeSendToAllRegistrants(true);
            showModal("confirmTransmission");
          }}
          disabled={!isDisabled.class && !isDisabled.activities}
        />
      </SendAllStudentBtnContainer>
    </WrapperSearchCondition>
  );
};

export default SearchCondition;
