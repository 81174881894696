import React, { useState, Fragment } from "react";
import { Grid } from "antd";
import { useTranslation } from "react-i18next";

import { Button, Box, Pagination, Select } from "components";
import { DownloadIcon, DownArrow } from "icons";
import { localStorageHelper } from "utils";
import { CheckboxStyled } from "screens/account/Account.style";

import {
  PaginationWrapper,
  ContentWrapper,
  SituationTableStyled,
  WrapperSizeChange,
  DownloadButtonsContainer,
  WapperSelectTimeForItem,
  PaginationContainer,
  NotifyWrapper,
  CheckboxText,
  TransmissionTitleContainer,
} from "./MentalHealth.style";
import ConfirmInterviewModal from "./ConfirmInterviewModal";

const tempDatas = [
  {
    id: 0,
    display_name: "小邦 将輝",
    date: "2022.11.12",
    stress_situation: "実施済",
    affiliation: "開発部",
    gender: "男性",
    checkbox: false,
    grade: "1年1組",
  },
  {
    id: 1,
    display_name: "福永 哲也",
    date: "2022.06.11",
    stress_situation: "未実施",
    affiliation: "営業部",
    gender: "女性",
    checkbox: true,
    grade: "1年1組",
  },
  {
    id: 2,
    display_name: "小邦 将輝",
    date: "",
    stress_situation: "未実施",
    affiliation: "営業部",
    gender: "男性",
    checkbox: true,
    grade: "1年1組",
  },
  {
    id: 3,
    display_name: "福永 哲也",
    date: "2022.04.06",
    stress_situation: "未実施",
    affiliation: "営業部",
    gender: "女性",
    checkbox: true,
    grade: "1年1組",
  },
  {
    id: 4,
    display_name: "小邦 将輝",
    date: "2022.05.02",
    stress_situation: "未実施",
    affiliation: "営業部",
    gender: "男性",
    checkbox: true,
    grade: "1年1組",
  },
  {
    id: 5,
    display_name: "福永 哲也",
    date: "",
    stress_situation: "未実施",
    affiliation: "営業部",
    gender: "女性",
    checkbox: true,
    grade: "1年1組",
  },
];

const { useBreakpoint } = Grid;

const MentalHealthSituationNotify: React.FC = () => {
  const currentLanguage = localStorageHelper.getItem("i18nextLng");
  const { t }: any = useTranslation("translation", { keyPrefix: "account" });
  const screens = useBreakpoint();
  const [confirmModalVisible, setConfirmModalVisible] =
    useState<boolean>(false);
  const [input, setInput] = useState({
    limit: 50,
    page: 1,
  });
  const totalRecord = 0;

  const handleCancel = () => {
    setConfirmModalVisible(false);
  };

  const returnStatus = (status: string) => {
    switch (status) {
      case "実施済":
        return "#B7B7B7";
      default:
        return;
    }
  };

  const columns = [
    {
      title: () => {
        return (
          <CheckboxText>
            <CheckboxStyled />
            <div>氏名</div>
          </CheckboxText>
        );
      },
      dataIndex: "display_name",
      key: "display_name",
      width: 132,
      fixed: window.innerWidth < 576 ? "" : "left",
      render: (text: string, record: any) => {
        return (
          <CheckboxText>
            {record?.checkbox ? (
              <CheckboxStyled />
            ) : (
              <div style={{ width: 20, height: 20 }}></div>
            )}
            <div>{text}</div>
          </CheckboxText>
        );
      },
    },
    {
      title: () => {
        return (
          <div>
            学年・
            <div>クラス</div>
          </div>
        );
      },
      dataIndex: "grade",
      key: "grade",
      width: 82,
    },
    {
      title: "性別",
      dataIndex: "gender",
      key: "gender",
      width: 82,
    },
    {
      title: "実施日",
      dataIndex: "date",
      key: "date",
      width: 132,
      render: (text: string) => {
        if (!text) {
          return <div>-</div>;
        }
        return <div>{text}</div>;
      },
    },
    {
      title: "実施状況",
      dataIndex: "stress_situation",
      key: "stress_situation",
      width: 102,
      render: (text: string, record: any) => {
        return (
          <div
            style={{
              color: returnStatus(record?.stress_situation),
              fontWeight: record?.stress_situation === "実施済" ? 500 : 400,
            }}
          >
            {text}
          </div>
        );
      },
    },
  ];

  const handleChangePage = (page: number) => {
    setInput((prevState: any) => ({ ...prevState, page }));
  };

  const handleOnChangeSizeChange = (limit: number) => {
    setInput((prevState: any) => ({ ...prevState, limit, page: 1 }));
  };

  const DownloadMenu = () => (
    <DownloadButtonsContainer>
      <Button
        icon={
          <DownloadIcon
            width="14px"
            height="14px"
            fill="currentColor"
            style={{ position: "absolute", left: 4, top: 4 }}
          />
        }
        name="CSV出力"
        background="#2AC769"
        color="#FFFFFF"
        border="none"
        fontSize={12}
        lineHeight="17px"
        fontWeight={700}
        padding="3px 8px 3px 14px"
      />
      <Button
        icon={
          <DownloadIcon
            width="14px"
            height="14px"
            fill="currentColor"
            style={{ position: "absolute", left: 4, top: 4 }}
          />
        }
        name="PDF出力"
        background="#2AC769"
        color="#FFFFFF"
        border="none"
        fontSize={12}
        lineHeight="17px"
        fontWeight={700}
        padding="3px 8px 3px 14px"
      />
    </DownloadButtonsContainer>
  );

  const ActionSection = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "8px 8px 9px",
        }}
      >
        <NotifyWrapper>
          <DownArrow />
          <div className="notify-title">チェックしたユーザーに</div>
          <Button
            color="#FFFFFF"
            fontSize={12}
            fontWeight={700}
            lineHeight="12px"
            background="#2F8CAE"
            padding="6px 12px 5px"
            name="通知を送る"
            bdr="6px"
            border="none"
            disabled={false}
            onClick={() => setConfirmModalVisible(true)}
          />
        </NotifyWrapper>

        <DownloadMenu />
      </div>
    );
  };

  const ShowPagination = () => {
    return totalRecord > input.limit ? (
      <PaginationWrapper>
        <Pagination
          current={input.page}
          onChange={handleChangePage}
          pageSize={input.limit}
          total={10}
          showSizeChanger={false}
          showLessItems={!screens.xl}
        />
      </PaginationWrapper>
    ) : (
      <Fragment></Fragment>
    );
  };

  return (
    <Box title={"実施状況（25問 小学生向け）状況"} padding="16px 16px 32px">
      <div style={{ padding: 16, marginBottom: 24 }}>
        <TransmissionTitleContainer>
          <div
            style={{
              fontSize: 18,
              lineHeight: "18px",
              fontWeight: 700,
            }}
          >
            実施検査選択
          </div>
          <Select placeholder="2022年6月こころの健康観察" width="278px" />
        </TransmissionTitleContainer>
        <div
          style={{
            fontSize: 18,
            lineHeight: "18px",
            fontWeight: 700,
            marginBottom: 16,
            display: "flex",
            alignItems: "center",
            gap: 106,
          }}
        >
          <div>ステータス</div>
          <div>クラス</div>
        </div>
        <div className="first-block">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 24,
            }}
          >
            <WapperSelectTimeForItem>
              <div className="bor-div-time">
                <div className="dright">
                  <Select
                    options={[]}
                    width={"172px"}
                    placeholder="選択してください"
                    height="40px"
                  />
                </div>
              </div>
            </WapperSelectTimeForItem>
            <WapperSelectTimeForItem>
              <div className="bor-div-time">
                <div className="dright">
                  <Select
                    options={[]}
                    width={"140px"}
                    placeholder="クラスを選択"
                    height="40px"
                  />
                </div>
              </div>
            </WapperSelectTimeForItem>
            <Button
              color="#FFFFFF"
              fontSize={16}
              fontWeight={700}
              lineHeight="23px"
              background={"#2AC769"}
              padding="10px 31px 12px"
              name={"検索"}
              bdr="6px"
              border="none"
              margin="0px 0px 0px 24px"
            />
          </div>
        </div>
        <Button
          color="#FFFFFF"
          fontSize={16}
          fontWeight={700}
          lineHeight="23px"
          background={"#2AC769"}
          padding="4px 34px 3px"
          name={"検索"}
          bdr="6px"
          border="none"
        />
      </div>
      <PaginationContainer
        style={{
          alignItems: "flex-start",
        }}
      >
        <WrapperSizeChange>
          <div style={{ fontWeight: 500 }}>表示件数：</div>
          <Select
            defaultValue={50}
            options={[]}
            onChange={handleOnChangeSizeChange}
          />
        </WrapperSizeChange>
        <ShowPagination />
      </PaginationContainer>
      <ContentWrapper>
        <SituationTableStyled
          dataSource={tempDatas}
          columns={columns}
          actionSection={<ActionSection />}
          scroll={{ x: "max-content" }}
          // loading={isLoading}
        />
      </ContentWrapper>
      {totalRecord > input.limit && <ShowPagination />}
      {/* <ConfirmInterviewModal
        isVisible={confirmModalVisible}
        handleCancel={handleCancel}
      /> */}
    </Box>
  );
};

export default MentalHealthSituationNotify;
